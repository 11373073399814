<template>
  <v-layout column justify-center fill-height class="text-input-mission">
    <!-- Mission question -->
    <v-flex d-flex class="mission-instructions">
      <ResizableText v-if="!hasImage" :message="currentMission.instructions" />
    </v-flex>
    <v-flex shrink>
      <image-uploader
        v-if="isScribe"
        :debug="1"
        :maxWidth="600"
        :quality="1"
        :autoRotate="true"
        outputFormat="verbose"
        :preview="true"
        :className="['fileinput', { 'fileinput--loaded': hasImage }]"
        capture="environment"
        accept=".jpg,.png,.jpeg"
        @input="setImage"
      >
        <label
          id="upload-image"
          ref="fileGrabber"
          for="fileInput"
          slot="upload-label"
        >
          <figure>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
            >
              <path
                class="path1"
                d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
              ></path>
            </svg>
          </figure>
          <span class="upload-caption">{{
            hasImage ? "Replace Image" : "Upload Image"
          }}</span>
        </label>
      </image-uploader>
    </v-flex>
    <TextField
      :placeholder="placeholder"
      :status="status"
      :show="showTextField"
      :buttonText="'SUBMIT'"
      @onSubmit="onSubmit"
      class="mission-text-feild"
    />
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex"
import { GameMixin } from "@/mixins"
import { uploadMedia, getBlobFromDataURL } from "@/services/storage.service"
import ResizableText from "./ResizableText.vue"
import TextField from "./TextField.vue"
import uniqid from "uniqid"

export default {
  name: "TextInput",
  mixins: [GameMixin],
  components: {
    ResizableText,
    TextField
  },
  props: {
    mode: String
  },
  // mounted() {
  //   // save the initial length
  //   const waitingTextLength = this.waitingText.length
  //   this.interval = setInterval(() => {
  //     if (this.waitingText.length > waitingTextLength + 2)
  //       this.waitingText = this.waitingText.substr(0, waitingTextLength)
  //     else this.waitingText = this.waitingText + "."
  //   }, 600)
  // },
  // destroyed() {
  //   clearInterval(this.interval)
  // },
  data() {
    return {
      // interval: null,
      waitingText: "Waiting on Scribe...",
      lastIncorrectPlays: 0,
      hasImage: false,
      loadingImage: false,
      imageUrl: ""
    }
  },
  computed: {
    ...mapGetters(["missionAnswers"]),
    ...mapGetters("auth", ["token"]),
    isWin() {
      return this.gotItRight
    },
    // if not a win and all tries are wasted
    isFail() {
      return !this.isWin && this.answers.length === this.numOfTries
    },
    isCompleted() {
      return this.isFail || this.isWin
    },
    currentMission() {
      return this.$store.getters.getCurrentMission
    },
    numOfTries() {
      var tmp = this.numOfTriesOriginal
      if (tmp == "Unlimited") tmp = 1000
      return parseInt(tmp)
    },
    correctAnswer() {
      return this.currentMission.answer
    },
    answers() {
      if (!this.missionPlays.length) return []
      // The admin gets 0 while a player gets their team ID
      const teamID = this.teamID || 0
      const plays = this.missionPlays.filter(play => play.teamID === teamID)
      if (plays.length) return plays[0].answer
      else return []
    },
    status() {
      return this.hasImage ? "active" : ""
    },
    lineThroughClass() {
      if (this.isFail || this.lastIncorrectPlays < this.incorrectPlays()) {
        this.lastIncorrectPlays = this.incorrectPlays()
        return " line-through"
      } else return ""
    },
    showTextField() {
      return this.mode !== "explain" ? "show" : ""
    },
    canPlay() {
      return (
        this.mode === "play" || this.mode === "huddle" || this.mode === "social"
      )
    },
    placeholder() {
      // No data entered for a non scribe
      if (!this.isScribe && !this.missionAnswers.length) return this.waitingText
      // Set the placeholder message for a non scrive
      // to the latest enetered by a scribe
      // On win
      if (!this.hasImage) return "Upload photo first"
      // If scrive and ready to go
      if (this.isScribe && this.canPlay) return "Enter caption here..."
      // Scribe but not ready to go
      return "Wait..."
    },
    isScribe() {
      return this.$store.getters.isScribe
    },
    isHost() {
      return this.user.role == "facilitator"
    }
  },
  methods: {
    async setImage(e) {
      try {
        this.loadingImage = true
        const fileName = `gamephotos/${uniqid()}-${this.user.username}.png`
        const blob = await getBlobFromDataURL(e.dataUrl)
        this.imageUrl = await uploadMedia({
          fileName,
          blob,
          token: this.token
        })
        this.hasImage = true
        this.loadingImage = false
      } catch (error) {
        console.log(error)
      }
    },
    async onSubmit(data) {
      if (!this.isScribe) return console.log("No rights for submit")
      if (!data) return console.log("Won't submit an empty string")
      this.caption = data
      await this.checkAnswer()
      this.caption = ""
      this.answer = ""
    }
  }
}
</script>

<style lang="scss">
.img-preview {
  max-height: 150px;
  max-width: 200px;
}
.fileinput {
  display: none;
}
#upload-image {
  cursor: pointer;
}
.text-input-mission {
  .mission-instructions {
    user-select: none;
    padding: 25px 10px 5px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .mission-text-feild {
    position: relative;
    margin-left: 60px;
    margin-right: 6px;
    margin-bottom: 6px;
    transform: translateY(10px);
    visibility: hidden;
    opacity: 0;
    transition: all 0.25s ease-out;
  }
  // .mission-options.show {
  //   visibility: visible;
  //   transform: none;
  //   opacity: 1;
  // }
}

// .is-categories {
//   .mission-instructions-row {
//     max-height: 42%;
//
//     &.full-height {
//       max-height: 100%;
//     }
//   }
//   .categories {
//     max-height: 0;
//     overflow: hidden;
//     transition: all 0.5s ease-out;
//     opacity: 0;
//
//     .category {
//       margin: 0 !important;
//       padding: 4px;
//     }
//     .col-6 {
//       width: 50%;
//     }
//     .col-4 {
//       width: 33.333333%;
//     }
//     .col-3 {
//       width: 25%;
//     }
//
//     &.show {
//       max-height: 100%;
//       opacity: 1;
//     }
//   }
//   .mission-options.show {
//     transition: all 0.25s ease-out 0.5s;
//   }
// }
</style>
