var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "text-input-mission",
      attrs: { column: "", "justify-center": "", "fill-height": "" }
    },
    [
      _c(
        "v-flex",
        { staticClass: "mission-instructions", attrs: { "d-flex": "" } },
        [
          !_vm.hasImage
            ? _c("ResizableText", {
                attrs: { message: _vm.currentMission.instructions }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { shrink: "" } },
        [
          _vm.isScribe
            ? _c(
                "image-uploader",
                {
                  attrs: {
                    debug: 1,
                    maxWidth: 600,
                    quality: 1,
                    autoRotate: true,
                    outputFormat: "verbose",
                    preview: true,
                    className: [
                      "fileinput",
                      { "fileinput--loaded": _vm.hasImage }
                    ],
                    capture: "environment",
                    accept: ".jpg,.png,.jpeg"
                  },
                  on: { input: _vm.setImage }
                },
                [
                  _c(
                    "label",
                    {
                      ref: "fileGrabber",
                      attrs: {
                        slot: "upload-label",
                        id: "upload-image",
                        for: "fileInput"
                      },
                      slot: "upload-label"
                    },
                    [
                      _c("figure", [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "32",
                              height: "32",
                              viewBox: "0 0 32 32"
                            }
                          },
                          [
                            _c("path", {
                              staticClass: "path1",
                              attrs: {
                                d:
                                  "M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                              }
                            })
                          ]
                        )
                      ]),
                      _c("span", { staticClass: "upload-caption" }, [
                        _vm._v(
                          _vm._s(
                            _vm.hasImage ? "Replace Image" : "Upload Image"
                          )
                        )
                      ])
                    ]
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c("TextField", {
        staticClass: "mission-text-feild",
        attrs: {
          placeholder: _vm.placeholder,
          status: _vm.status,
          show: _vm.showTextField,
          buttonText: "SUBMIT"
        },
        on: { onSubmit: _vm.onSubmit }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }